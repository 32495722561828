<template>
	<b-card
		border-variant="mmi-blue"
		header-bg-variant="mmi-blue"
		header-text-variant="white"
		class="d-flex flex-row"
	>
		<template #header>
			<h6 class="m-0 p-2 text-center" @click="toggle">LEGENDA</h6>
		</template>
		<div class="d-flex align-items-center">
			<table id="tb-legend" class="table table-borderless m-0">
				<tr>
					<th rowspan="2" class="text-center">ALARMES <br> ATIVOS</th>
					<td><div class="bg-danger"></div></td>
					<td>Alarme HH (High High) <br> / Alarme LL (Low Low)</td>
				</tr>
				<tr>
					<td><div class="bg-warning"></div></td>
					<td>Alarme H (High) <br> / Alarme L (Low)</td>
				</tr>

				<tr>
					<th rowspan="2" class="text-center">ALARMES <br> NÃO ATIVOS</th>
					<td><div class="bg-danger-off text-center count">X</div></td>
					<td>Alarme HH (High High) <br> / Alarme LL (Low Low)</td>
				</tr>
				<tr>
					<td><div class="bg-warning-off text-center count">X</div></td>
					<td>Alarme H (High) <br> / Alarme L (Low)</td>
				</tr>
			</table>
		</div>
		<small> <b>X</b> indica o número de ocorrências do alarme.</small>
	</b-card>
</template>

<script>
	export default {
		methods: {
			toggle () {
				this.$emit("toggle");
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 355px;

		.card-header {
			padding: 0;
			border: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			writing-mode: vertical-lr;
			text-orientation: upright;

			h6:hover {
				cursor: pointer;
			}
		}

		.card-body {
			padding: 0.25rem 0.75rem;
		}
	}

	.table th, .table td {
		padding: 0.25rem;
		vertical-align: middle;
	}

	.border-mmi-blue {
		border-color: #6dcff6 !important;
	}

	.bg-mmi-blue {
		background-color: #6dcff6 !important;
	}

	.bg-warning {
		background-color: #FAAF40 !important;
	}

	.bg-danger {
		background-color: #EC1C24 !important;
	}

	.bg-warning-off {
		background-color: #facf8e !important;
	}

	.bg-danger-off {
		background-color: #eb8286 !important;
	}

	.count {
		color: black
	}

	#tb-legend {
		margin-left: 0.75rem;
		padding-left: 0.75rem;

		td {
			div {
				width: calc(28px - 0.25rem);
				height: calc(28px - 0.25rem);
				border-radius: 2px;
			}
		}
	}
</style>
