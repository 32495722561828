import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class AlarmesService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async listaAlarmes (idMaquina) {
		const response = await this.axios.get("alarms", { params: { idMaquina } });
		return response.data;
	}

	async listaAlarmesAtivos (maquina, iolink) {
		const response = await this.axios.get("alarms/active", { params: { ...maquina, iolink } });
		return response.data;
	}

	async reconhecerAlarme (alarme) {
		const resposta = await this.axios.post("alarms/recognize", alarme);
		return resposta;
	}
}
