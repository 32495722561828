<template>
	<div id="responsive-div" :style="`max-width: calc(100vw - ${sideBarWidth}px - ${alarmsPanelWidth}px); margin-left: ${sideBarWidth}px;`">
		<alarms-list
			@alarmes_log="updateEstadoTela"
			:idMaquina="idMaquinaSelecionada"
			:nomeMaquina="nomeMaquinaSelecionada"
			:style="`width: ${alarmsPanelWidth}px;`"
		/>

		<Legend
			id="legend"
			@toggle="showLegend = !showLegend"
			:style="`right: ${showLegend ? '0.5rem' : '-320px'}; margin-right: ${alarmsPanelWidth}px;`"
		/>

		<header class="mb-2 pt-2">
			<div class="filters d-flex flex-column">
				<a href="" class="filters-handle d-inline-flex align-self-center p-2 __rounded" data-toggle="collapse" data-target=".filters-content" aria-expanded="false" aria-controls="filers">
					<div class="d-flex">
						<p class="mb-0 mr-1">Filtros</p>
						<FilterIcon />
					</div>
				</a>
				<div class="wrapper align-self-center">
					<div class="filters-content collapse mt-2">
						<div class="card tcs-card elevate-2 borderless tcs-top container py-3">
							<form @submit.prevent="search">
								<div class="form-row">
									<div class="form-group col-sm-6">
										<label for="gerencia">Área</label>
										<vue-multi-select
											class="multi-100"
											searchPlaceholder="Pesquisar"
											v-model="areaSelecionada"
											:options="{ multi: false, labelName: 'nome' }"
											:selectOptions="areasOpcoes"
											@input="filtraMaquinas"
											:btnLabel="() => (areaSelecionada[0] && areaSelecionada[0].nome) || 'Selecione'"
										/>
									</div>
									<div class="form-group col-sm-6">
										<label for="gerencia">Máquina</label>
										<vue-multi-select
											class="multi-100"
											searchPlaceholder="Pesquisar"
											:disabled="!areaSelecionada.length"
											v-model="maquinaSelecionada"
											:options="{ multi: false, labelName: 'nome' }"
											:selectOptions="maquinasOpcoes"
											:btnLabel="() => (maquinaSelecionada[0] && maquinaSelecionada[0].nome) || 'Selecione'"
										/>
									</div>
								</div>

								<div class="d-flex justify-content-end tcs-card-footer-action">
									<button
										class="btn btn-filter tcs-btn"
										type="submit"
										data-toggle="collapse"
										data-target=".filters-content"
										data-cy="Pesquisar"
										:disabled="(!areaSelecionada[0] || !maquinaSelecionada[0])"
									>
										<SearchIcon />
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</header>

		<div id="card-arquitetura" class="py-3 mx-auto mb-3 p-0" :style="`width: calc(100% - 48px - ${sideBarWidth}px); max-width: calc(100vw - ${sideBarWidth}px - ${alarmsPanelWidth}px);`">
			<p class="text-center my-auto" v-if="!pesquisaRealizada">
				Selecione a área e a máquina desejada.
			</p>
			<p class="text-center my-auto alert alert-warning reveal-animation mb-0 mx-3" v-if="!linkArquitetura && pesquisaRealizada">
				Arquitetura de sensoriamento não cadastrada no sistema.
			</p>

			<div class="position-absolute w-100">

				<img v-if="linkArquitetura && pesquisaRealizada" :src="linkArquitetura" class="position-relative w-100 bg-white">

				<ul v-if="alarmesSensoresTela.length && linkArquitetura && pesquisaRealizada" class="m-0">
					<li v-for="(alarme, idx) in alarmesSensoresTela" :key="idx" >
						<div
							data-toggle="tooltip"
							class="position-absolute d-flex align-items-end"
							:data-original-title="alarme.descricao"
							:style="`width: 6.98947%; height: 4.18947%; z-index: 2; top: ${alarme.y}%; left: ${alarme.x}%;`"
						>
							<img :src="alarme.img" class="position-relative w-100 h-100" @load="setTooltip">
							<span
								style="font-size: 1.2em; top: 90%;"
								:style="alarme.tipo == 'NA' ? 'position: absolute; left: 80%' : 'position: relative'"
								:class="{
									'hhll': ((alarme.nivel === 'HH' || alarme.nivel === 'LL') && alarme.state === alarme.estado_interesse),
									'hl': ((alarme.nivel === 'L' || alarme.nivel === 'H') && alarme.state === alarme.estado_interesse),
									'hhlloff': ((alarme.nivel === 'HH' || alarme.nivel === 'LL') && alarme.state !== alarme.estado_interesse),
									'hloff': ((alarme.nivel === 'L' || alarme.nivel === 'H') && alarme.state !== alarme.estado_interesse)
								}"
							>
								{{ alarme.nivel }}
							</span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";
	import $ from "jquery";

	import AlarmsList from "@/components/Sensoriamento/AlarmsList.vue";
	import Legend from "@/components/Sensoriamento/Legend.vue";

	import { AreasService } from "../services/areas";
	import { MaquinasService } from "../services/maquinas";

	export default {
		components: { AlarmsList, VueMultiSelect, Legend },

		data () {
			return {
				areasOpcoes: [],
				areaSelecionada: [],
				maquinasOpcoes:[],
				maquinaSelecionada: [],
				idMaquinaSelecionada: null,
				nomeMaquinaSelecionada: null,
				linkArquitetura: "",
				imagemCarregada: false,
				alarmsPanelWidth: window.innerWidth <= 790 ? 0 : 390,
				pesquisaRealizada: false,
				showLegend: false,
				alarmesSensoresTela: [],
				tooltips: true,

				/**
				 * Associa a id da máquina a flag de requisição de localização pendente
				 * @type {Record<number, boolean>}
				 */
				temRequisicaoPendente: {},

				areasService: new AreasService(),
				maquinasService: new MaquinasService()
			};
		},

		computed: mapState(["sideBarWidth"]),

		async mounted () {
			window.addEventListener("resize", this.windowResize);

			this.maquinas = (await this.maquinasService.listMachines() || []).map(m => ({
				nome: m.nome,
				...m
			}));

			this.areasOpcoes = (await this.areasService.listAreas() || []).map(m => ({
				nome: m.nome,
				...m
			}));

			if (this.areasOpcoes.length === 1)
				this.areaSelecionada = [this.areasOpcoes[0]];

			this.filtraMaquinas();
		},

		destroyed () {
			window.removeEventListener("resize", this.windowResize);
		},

		methods: {
			windowResize () {
				this.alarmsPanelWidth = window.innerWidth <= 790 ? 0 : 390;
			},

			filtraMaquinas () {
				this.maquinaSelecionada = [];
				if (this.areaSelecionada[0])
					this.maquinasOpcoes = this.maquinas.filter(m => m.id_area == this.areaSelecionada[0].id);
			},

			search () {
				this.idMaquinaSelecionada = this.maquinaSelecionada[0].id;
				this.nomeMaquinaSelecionada = this.maquinaSelecionada[0].nome.replaceAll("-", "");
				this.tooltips = true;
			},

			updateEstadoTela (infosTela, link) {
				this.pesquisaRealizada = true;
				this.alarmesSensoresTela = [];
				this.linkArquitetura = link;
				this.alarmesSensoresTela = infosTela;
			},

			setTooltip () {
				if (this.tooltips) {
					$('[data-toggle="tooltip"]').tooltip();
					this.tooltips = false; // Para evitar chamadas desnecessárias do jquery
				}
			}
		}
	};
</script>

<style scoped>
	#responsive-div {
		margin-top: 66px;
	}

	.tcs-card.tcs-top {
		margin: 0;
	}

	#card-arquitetura {
		position: relative;
		border-top: 10px solid #6dcff6 !important;
		background-color: white;
		border-radius: 10px;
		padding-right: 15px;
		padding-left: 15px;
	}

	.alarms-list {
		position: absolute;
		height: calc(100% - 66px);
		z-index: 4;
		right: 0;
	}

	.wrapper {
		z-index: 25;
		width: calc(100% - 30px);
		max-width: 600px;
	}

	#legend {
		position: absolute;
		transition: right 1s;
		bottom: 40px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
		z-index: 3;
	}

	.hl {
		color: #FAAF40 !important;
		font-weight: 900;
		line-height: 16.41px;
	}

	.hloff {
		color: #FAAF40 !important;
		opacity: .3;
		font-weight: 900;
		line-height: 16.41px;
	}

	.hhll {
		color: #EC1C24 !important;
		font-weight: 900;
		line-height: 16.41px;
	}

	.hhlloff {
		color: #EC1C24 !important;
		opacity: .3;
		font-weight: 900;
		line-height: 16.41px;
	}

	@media (max-width: 790px) {
		.card.tcs-card.tcs-top {
			border-radius: 10px !important;
		}
	}

	@media (max-width: 850px) {
		#legend {
			display: none !important;
		}

		#responsive-div {
			width: 100%;
		}
	}
</style>
